<template>
  <div>
    <SendLabResultModal
      v-if="null != this.labResult"
      :order_id="this.labOrder.id"
      :result_id="this.labResult.id || 0"
      :email_addresses="this.resultReceipients"
      :order_number="this.labOrder.number"
      :result_number="this.labResult.number"
      redirect="/lab/results/waiting"
    />
    <div v-if="this.loader" style="display: flex; justify-content: center">
      <svg
        version="1.1"
        id="L9"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
        width="70"
      >
        <path
          fill="#3699FF"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
    <div v-if="this.loader == false">
      <div class="row">
        <div class="col-xl-6">
          <h3>Dane podstawowe</h3>
          <b-form-group label="Zlecenie laboratoryjne">
            <b-form-input v-model="form.result_data.order_number" disabled />
          </b-form-group>
          <div class="row">
            <div class="col-xl-6">
              <b-form-group label="Data rozpoczęcia badania">
                <b-form-datepicker
                  v-model="form.start_date"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :disabled="this.form.accepted"
                />
              </b-form-group>
            </div>
            <div class="col-xl-6">
              <b-form-group label="Data zakończenia badania">
                <b-form-datepicker
                  v-model="form.end_date"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :disabled="this.form.accepted"
                />
              </b-form-group>
            </div>
          </div>

          <b-form-group label="Data wystawienia dokumentu">
            <b-form-datepicker
              v-model="form.date_created"
              :start-weekday="1"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :disabled="this.form.accepted"
            />
          </b-form-group>

          <b-form-group
            label="Wybierz próbki dla których zostanie wprowadzony wynik"
          >
            <b-button
              @click="allSamples"
              variant="primary"
              size="sm"
              class="mb-2"
              >Wybierz wszystkie</b-button
            >
            <b-form-checkbox-group
              v-model="form.result_data.samplesSelected"
              :options="samplesList"
            ></b-form-checkbox-group>
          </b-form-group>

          <div class="row mb-2" v-if="!isVet">
            <div class="col-xl-6">
              <b-form-group label="Próba zbiorcza?">
                <b-form-select
                  v-model="form.result_data.groupResult"
                  :options="[
                    { value: 'false', text: 'Nie' },
                    { value: 'true', text: 'Tak' },
                  ]"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-xl-6" v-if="form.result_data.groupResult == 'true'">
              <b-form-group label="Metoda badwcza dla próby zbiorczej">
                <b-form-select
                  v-model="form.result_data.groupResultMethod"
                  :options="availableMethods"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>

          <div
            class="row mb-2"
            v-if="form.result_data.groupResult == 'false' && !isVet"
          >
            <div class="col-xl-6">
              <b-form-group
                label="Wybierz metody badawcze dla których zostaną wprowadzone wyniki"
              >
                <b-form-checkbox-group
                  v-model="form.result_data.selectedMethods"
                  :options="availableMethods"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>

          <!-- <b-form-group label="Próba podwójna?" v-if="!isVet">
                        <b-form-select
                            :disabled="form.result_data.groupResult == 'true'"
                            v-model="form.result_data.doubleResult"
                            :options="[
                                { value: 'false', text: 'Nie'},
                                { value: 'true', text: 'Tak'}
                            ]"
                        ></b-form-select>
                    </b-form-group> -->
        </div>
        <div class="col-xl-6">
          <h3>Raport z badania</h3>

          <table>
            <tr>
              <td>Dotyczy zlecenia nr:</td>
              <td>{{ this.form.result_data.order_number }}</td>
            </tr>
            <tr>
              <td>Zleceniodawca:</td>
              <td>{{ this.form.result_data.payer }}</td>
            </tr>
            <tr>
              <td>Rodzaj, opis próbki:</td>
              <td>{{ this.form.result_data.sample_type }}</td>
            </tr>
            <tr>
              <td>Stan próbki w chwili przyjęcia:</td>
              <td>{{ this.form.result_data.sample_state }}</td>
            </tr>
            <tr>
              <td>Data pobrania:</td>
              <td>{{ this.form.result_data.sampling_date }}</td>
            </tr>
            <tr>
              <td>Osoba pobierająca:</td>
              <td>{{ this.form.result_data.sampling_person }}</td>
            </tr>
            <tr>
              <td>Data przyjęcia próbki:</td>
              <td>{{ this.form.result_data.sample_admitted }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row" v-if="!isVet">
        <div class="col-xl-12">
          <h3>Wyniki badania</h3>
          <table>
            <tr>
              <td>Nr próbki</td>
              <td>Opis próbki</td>
              <td>Parametr</td>
              <td>Metoda badawcza</td>
              <td>Wynik</td>
              <td>Jednostka</td>
            </tr>
            <template v-if="form.result_data.groupResult != 'true'">
              <tr
                v-for="(sample, index) in resultsToShow"
                v-bind:key="'sample' + index"
              >
                <td>{{ sample.number }}</td>
                <td>
                  <b-form-input
                    v-model="sample.description"
                    :disabled="form.accepted"
                  ></b-form-input>
                </td>
                <td>{{ sample.param }}</td>
                <td>{{ sample.method }}</td>
                <td>
                  <auto-suggest-input
                    v-model="sample.result"
                    :disabled="form.accepted"
                    :options="suggestions"
                  />
                  <!-- <b-form-textarea
                                        v-model="sample.result"
                                        :disabled="form.accepted"        
                                    ></b-form-textarea> -->
                  <auto-suggest-input
                    v-if="sample.isDouble"
                    v-model="sample.double_result"
                    :disabled="form.accepted"
                    :options="suggestions"
                  ></auto-suggest-input>
                </td>
                <td>
                  <b-form-select
                    v-model="sample.unit"
                    :options="resultUnits"
                  ></b-form-select>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr
                v-for="(sample, index) in resultsToShow"
                v-bind:key="'sample' + index"
              >
                <td>{{ sample.number }}</td>
                <td>
                  <b-form-input
                    v-model="sample.description"
                    :disabled="form.accepted"
                  ></b-form-input>
                </td>
                <template v-if="index == 0">
                  <td :rowspan="resultsToShow.length">
                    {{ getGroupMethodParam() }}
                  </td>
                  <td :rowspan="resultsToShow.length">
                    {{ getGroupMethodMethod() }}
                  </td>
                  <td :rowspan="resultsToShow.length">
                    <auto-suggest-input
                      v-model="form.result_data.group_result"
                      :disabled="form.accepted"
                      :options="suggestions"
                    ></auto-suggest-input>
                  </td>
                  <td>
                    <b-form-select
                      v-model="sample.unit"
                      :options="resultUnits"
                    ></b-form-select>
                  </td>
                </template>
              </tr>
            </template>
          </table>
        </div>
      </div>

      <template v-if="isVet">
        <div class="row">
          <div class="col-xl-12">
            <template v-if="this.availableMethodsIds.includes(37)">
              <h4>Badanie anatomo-patologiczne:</h4>
              <table style="margin-bottom: 20px">
                <tr>
                  <!-- <th>Wybierz</th> -->
                  <th>Szt.</th>
                  <th>Opis</th>
                </tr>
                <template v-for="(row, index) in form.result_data.vet.anatom">
                  <tr v-bind:key="'anatom' + index" v-if="row.other">
                    <!-- <td>
                                            <b-checkbox v-model="row.selected"></b-checkbox>
                                        </td> -->
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.amount"
                      ></b-input>
                    </td>
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.desc"
                      ></b-input>
                    </td>
                  </tr>
                  <tr v-bind:key="'anatom' + index" v-else>
                    <!-- <td>
                                            <b-checkbox v-model="row.selected"></b-checkbox>
                                        </td> -->
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.amount"
                      ></b-input>
                    </td>
                    <td>{{ row.desc }}</td>
                  </tr>
                </template>
              </table>
            </template>

            <template v-if="this.availableMethodsIds.includes(39)">
              <h4>Badanie bakteriologiczne:</h4>
              <table style="margin-bottom: 20px">
                <tr>
                  <!-- <th>Wybierz</th> -->
                  <th>Szt.</th>
                  <th>Opis</th>
                </tr>
                <template v-for="(row, index) in form.result_data.vet.bacteria">
                  <tr v-bind:key="'bacteria' + index" v-if="row.other">
                    <!-- <td>
                                            <b-checkbox v-model="row.selected"></b-checkbox>
                                        </td> -->
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.amount"
                      ></b-input>
                    </td>
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.desc"
                      ></b-input>
                    </td>
                  </tr>
                  <tr
                    v-else-if="row.salmonella"
                    v-bind:key="'bacteria' + index"
                  >
                    <!-- <td>
                                            <b-checkbox v-model="row.selected"></b-checkbox>
                                        </td> -->
                    <td>
                      <b-form-select
                        v-model="row.value"
                        :options="[
                          {
                            text: '',
                            value: 'n/a',
                          },
                          {
                            text: 'Wykryto',
                            value: 'Wykryto',
                          },
                          {
                            text: 'Nie wykryto',
                            value: 'Nie wykryto',
                          },
                        ]"
                      ></b-form-select>
                    </td>
                    <td>{{ row.desc }}</td>
                  </tr>
                  <tr v-bind:key="'bacteria' + index" v-else-if="row.growth">
                    <td>
                      <b-form-select
                        v-model="row.value"
                        :options="[
                          {
                            text: 'Nie',
                            value: 'false',
                          },
                          {
                            text: 'Tak',
                            value: 'true',
                          },
                        ]"
                      ></b-form-select>
                    </td>
                    <td>{{ row.desc }}</td>
                  </tr>
                  <tr v-bind:key="'bacteria' + index" v-else>
                    <!-- <td>
                                            <b-checkbox v-model="row.selected"></b-checkbox>
                                        </td> -->
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.amount"
                      ></b-input>
                    </td>
                    <td>{{ row.desc }}</td>
                  </tr>
                </template>
              </table>
            </template>

            <template v-if="this.availableMethodsIds.includes(41)">
              <h4>Badanie mykologiczne:</h4>
              <table style="margin-bottom: 20px">
                <tr>
                  <!-- <th>Wybierz</th> -->
                  <th>Szt.</th>
                  <th>Opis</th>
                </tr>
                <template v-for="(row, index) in form.result_data.vet.miko">
                  <tr v-bind:key="'miko' + index" v-if="row.other">
                    <!-- <td>
                                            <b-checkbox v-model="row.selected"></b-checkbox>
                                        </td> -->
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.amount"
                      ></b-input>
                    </td>
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.desc"
                      ></b-input>
                    </td>
                  </tr>
                  <tr v-bind:key="'miko' + index" v-else-if="row.growth">
                    <td>
                      <b-form-select
                        v-model="row.value"
                        :options="[
                          {
                            text: 'Nie',
                            value: 'false',
                          },
                          {
                            text: 'Tak',
                            value: 'true',
                          },
                        ]"
                      ></b-form-select>
                    </td>
                    <td>{{ row.desc }}</td>
                  </tr>
                  <tr v-bind:key="'miko' + index" v-else>
                    <!-- <td>
                                            <b-checkbox v-model="row.selected"></b-checkbox>
                                        </td> -->
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.amount"
                      ></b-input>
                    </td>
                    <td>{{ row.desc }}</td>
                  </tr>
                </template>
              </table>
            </template>

            <template v-if="this.availableMethodsIds.includes(43)">
              <h4>Badanie parazytologiczne:</h4>
              <b-form-textarea
                v-model="form.result_data.vet.parazyt"
              ></b-form-textarea>
            </template>

            <template v-if="this.availableMethodsIds.includes(40)">
              <h4>Antybiogram</h4>
              <div style="display: none">
                <div class="row">
                  <div class="col-xl-3">
                    <b-form-group label="Metoda badwcza dla antybiogramu">
                      <b-form-select
                        style="height: 20px !important"
                        v-model="form.result_data.vet.biogram_method"
                        :options="availableMethods"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <table style="margin-bottom: 20px">
                <tr>
                  <th>Nazwa</th>
                  <th>
                    1
                    <b-form-input
                      style="
                        height: 20px !important;
                        display: inline-block;
                        width: 80%;
                        margin-left: 10px;
                      "
                      v-model="form.result_data.vet.biogram_1"
                    ></b-form-input>
                  </th>
                  <th>
                    2
                    <b-form-input
                      style="
                        height: 20px !important;
                        display: inline-block;
                        width: 80%;
                        margin-left: 10px;
                      "
                      v-model="form.result_data.vet.biogram_2"
                    ></b-form-input>
                  </th>
                </tr>
                <template v-for="(row, index) in form.result_data.vet.biogram">
                  <tr v-bind:key="'miko' + index">
                    <td>
                      {{ row.desc }}
                    </td>
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.one"
                      ></b-input>
                    </td>
                    <td>
                      <b-input
                        style="height: 20px !important"
                        v-model="row.two"
                      ></b-input>
                    </td>
                  </tr>
                </template>
              </table>
            </template>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-xl-6">
          <b-button
            @click="saveResult"
            variant="primary"
            class="mt-3"
            v-if="form.accepted != true"
            >Zapisz</b-button
          >
          <b-button
            @click="accept"
            variant="warning"
            class="mt-3 ml-4"
            v-if="
              form.accepted != true &&
              this.currentUserPersonalInfo.roles.includes('acceptance')
            "
          >
            Zatwierdź wynik
          </b-button>
        </div>
        <div class="col-xl-6">
          <b-button @click="printResult" variant="primary" class="mt-3"
            >Drukuj</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AutoSuggestInput from "../../../content/form/AutoSuggestInput.vue";
import SendLabResultModal from "../../../content/modal/SendLabResultModal.vue";

// researchLinesDouble.filter( el => el.index == sample.index )

export default {
  name: "LabResultForm",
  // How do we structure the URL for this?
  // We can have result id, or order id for which we create the result
  components: {
    AutoSuggestInput,
    SendLabResultModal,
  },
  computed: {
    ...mapGetters([
      "labOrder",
      "researchLinesRaw",
      "labResult",
      "currentUserPersonalInfo",
    ]),
    resultReceipients() {
      return [
        ...(this.labOrder?.content?.receiverEmails?.additional ?? []),
        ...(this.labOrder?.content?.receiverEmails?.basic ?? []),
      ];
    },
    samplesList() {
      // { text: "", value: "" }
      if (this.labOrder?.content?.researchLines) {
        return this.labOrder?.content?.researchLines.map((sample) => {
          return {
            text:
              this.form.result_data.order_number.split("/")[0] +
              "-" +
              sample.index +
              "/" +
              this.form.result_data.order_number
                .split("/")
                .splice(1, 4)
                .join("/"),
            value: sample.index,
          };
        });
      }

      return [];
    },
    resultsToShow() {
      if (this.form.result_data.groupResult == "true")
        return this.form.result_data.results.filter(
          (el) =>
            this.form.result_data.samplesSelected.includes(el.index) &&
            el.method_id == this.form.result_data.groupResultMethod
        );

      return this.form.result_data.results.filter((el) =>
        this.form.result_data.samplesSelected.includes(el.index)
      );
    },
    availableMethods() {
      let ret = {};
      for (const sample of this.labOrder?.content?.researchLines.filter((s) =>
        this.form.result_data.samplesSelected.includes(s.index)
      )) {
        let methods = this.researchLinesRaw
          .filter((m) => sample.research.includes(m.id))
          .map((method) => {
            return {
              text: method.parameter + " " + method.method,
              value: method.id,
            };
          });
        for (const m of methods) ret[m.value] = m;
      }

      return Object.values(ret);
    },
    availableMethodsIds() {
      return this.availableMethods.map((el) => el.value);
    },
    isVet() {
      if (null == this.labOrder) return false;

      return this.labOrder.matrix == "weterynaria";
    },
    isUrzedowe() {
      if (null == this.labOrder) return false;

      return this.labOrder.matrix == "urzedowe";
    },
    suggestions() {
      if (this.isUrzedowe)
        return [
          "Salmonella spp. niewykryte w próbce analitycznej 2 par okładzin na buty. Nie stwierdzono efektu hamującego wzrost bakterii.",
          "Salmonella spp. niewykryte w próbce analitycznej 3 par okładzin na buty. Nie stwierdzono efektu hamującego wzrost bakterii.",
          "Salmonella spp. niewykryte w próbce analitycznej 25g kału. Nie stwierdzono efektu hamującego wzrost bakterii.",
          "Salmonella spp. niewykryte w próbce analitycznej 25g mekonium. Nie stwierdzono efektu hamującego wzrost bakterii.",
          "Salmonella spp. niewykryte w próbce analitycznej 25g narządów pobranych z piskląt. Nie stwierdzono efektu hamującego wzrost bakterii.",
        ];
      else
        return [
          "Salmonella spp. niewykryte w próbce analitycznej 25g.",
          "Salmonella spp. niewykryte w próbce analitycznej 2 par okładzin na buty.",
          "Salmonella spp. niewykryte w próbce analitycznej wymazu.",
          "Salmonella spp. niewykryte w próbce analitycznej puchu.",
          "Listeria monocytogenes niewykryte w próbce analitycznej 25g.",
          "Listeria monocytogenes . niewykryte w próbce analitycznej wymazu.",
        ];
    },
  },
  data() {
    return {
      loader: true,
      resultUnits: [
        {
          text: "",
          value: "",
        },
        {
          text: "jtk/ml",
          value: "jtk/ml",
        },
        {
          text: "ml",
          value: "ml",
        },
        {
          text: "jtk/g",
          value: "jtk/g",
        },
        {
          text: "g",
          value: "g",
        },
        {
          text: "jtk/100ml ",
          value: "jtk/100ml ",
        },
      ],
      result_id: 0,
      form: {
        lab_order_id: null,
        title: "",
        start_date: "",
        end_date: "",
        date_created: "",
        norm: "",
        type: "",
        result_data: {
          samplesSelected: [],
          order_number: "",
          payer: "",
          sample_type: "",
          sample_state: "",
          sampling_date: "",
          sampling_person: "",
          sample_admitted: "",
          results: [],
          groupResult: "false",
          groupResultMethod: "",
          doubleResult: "false",
          group_result: "",
          vet: {
            biogram_method: "",
            biogram_1: "",
            biogram_2: "",
            anatom: [
              {
                desc: "szt. zapalenie woreczka żółtkowego",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. skaza moczanowa nerkowa",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. przekrwienie płuc",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. zażółcenie wątroby",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. wzdęcie jelit",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. zapalny pępek",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. przekrwienie wątroby",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. przekrwienie nerek",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. włóknikowe zapalenie otrzewnej",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. włóknikowe zapalenie osierdzia",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. włóknikowe zapalenie torebki wątrobowej",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. włóknikowe zapalenie otrzewnej, osierdzia i torebki wątrobowej",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nieżyt jelit cienkich",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nieżyt jelit cienkich z obecnością ciągliwego śluzu",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obecność czopów grzybiczych w tkance płucnej",
                amount: "",
                selected: false,
              },
              {
                desc: "Inne",
                amount: "",
                selected: false,
                other: true,
              },
            ],
            parazyt: "",
            bacteria: [
              {
                desc: "szt. obfity wzrost E.coli",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost E.coli",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost E.coli",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. pojedyncze kolonie E.coli",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Staphylococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Staphylococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Staphylococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. pojedyncze kolonie Staphylococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Streptococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Streptococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Streptococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. pojedyncze kolonie Streptococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Enterococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Enterococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Enterococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. pojedyncze kolonie Enterococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. wzrost Proteus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. wzrost Pseudomonas aeruginosa",
                amount: "",
                selected: false,
              },
              {
                desc: "Inne",
                amount: "",
                selected: false,
                other: true,
              },
              {
                desc: "Wynik badania na obecność pałeczek z rodzaju Salmonella",
                value: false,
                salmonella: true,
                selected: false,
              },
              {
                desc: "Brak wzrostu",
                value: false,
                growth: true,
              },
            ],
            miko: [
              {
                desc: "szt. obfity wzrost Aspergillus fumigatus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Aspergillus fumigatus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Aspergillus fumigatus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Aspergillus niger",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Aspergillus niger",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Aspergillus niger",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Aspergillus flavus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Aspergillus flavus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Aspergillus flavus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Aspergillus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "Inne",
                amount: "",
                selected: false,
                other: true,
              },
              {
                desc: "Brak wzrostu",
                value: false,
                growth: true,
              },
            ],
            biogram: [
              {
                desc: "AMOKSIKLAV",
                one: "",
                two: "",
              },
              {
                desc: "AMOKSYCYLINA",
                one: "",
                two: "",
              },
              {
                desc: "BIOMOX",
                one: "",
                two: "",
              },
              {
                desc: "HYDRODOXX",
                one: "",
                two: "",
              },
              {
                desc: "DOXY",
                one: "",
                two: "",
              },
              {
                desc: "SPECTRON",
                one: "",
                two: "",
              },
              {
                desc: "ENROFLOKSACYNA",
                one: "",
                two: "",
              },
              {
                desc: "FLUMEQUINA",
                one: "",
                two: "",
              },
              {
                desc: "BIOFLUMEQ",
                one: "",
                two: "",
              },
              {
                desc: "TRIMSULFASOL",
                one: "",
                two: "",
              },
              {
                desc: "METHOXASOL",
                one: "",
                two: "",
              },
              {
                desc: "FLORFENICOL",
                one: "",
                two: "",
              },
              {
                desc: "FLORON",
                one: "",
                two: "",
              },
              {
                desc: "LINCO-SPECTIN",
                one: "",
                two: "",
              },
              {
                desc: "NEOSOL",
                one: "",
                two: "",
              },
              {
                desc: "NEOMICIN",
                one: "",
                two: "",
              },
              {
                desc: "LINCOMYCIN",
                one: "",
                two: "",
              },
              {
                desc: "COLISTYNA",
                one: "",
                two: "",
              },
              {
                desc: "THIAMPHENICOL",
                one: "",
                two: "",
              },
              {
                desc: "TYLAN",
                one: "",
                two: "",
              },
              {
                desc: "OXYTERACYKLINA",
                one: "",
                two: "",
              },
              {
                desc: "MARBOCYL",
                one: "",
                two: "",
              },
              {
                desc: "BACOLAM",
                one: "",
                two: "",
              },
              {
                desc: "TIRSAN",
                one: "",
                two: "",
              },
              {
                desc: "DIFLOXY",
                one: "",
                two: "",
              },
              {
                desc: "STREPTOMYCYNA",
                one: "",
                two: "",
              },
              {
                desc: "GENTAMYCYNA",
                one: "",
                two: "",
              },
            ],
          },
          selectedMethods: [],
        },
        accepted: false,
      },
    };
  },
  methods: {
    isDouble(sample) {
      let c = this.labOrder?.content?.researchLinesDouble.filter(
        (el) => el.index == sample.index
      );

      if (c.length == 0) return false;

      return c[0].research.includes(sample.method_id);
    },
    allSamples() {
      if (
        this.form.result_data.samplesSelected.length != this.samplesList.length
      )
        this.form.result_data.samplesSelected = this.samplesList.map(
          (el) => el.value
        );
      else this.form.result_data.samplesSelected = [];

      this.$forceUpdate();
    },
    getGroupMethod() {
      if (this.form.result_data.groupResultMethod > 0)
        return this.researchLinesRaw.filter(
          (el) => el.id == this.form.result_data.groupResultMethod
        )[0];

      return null;
    },
    getGroupMethodParam() {
      let m = this.getGroupMethod();

      if (null !== m) return m.parameter;

      return "";
    },
    getGroupMethodMethod() {
      let m = this.getGroupMethod();

      if (null !== m) return m.method;

      return "";
    },
    saveResult() {
      this.$store.dispatch("labResultAdd", {
        data: {
          form: this.form,
          result_id: this.result_id,
        },
      });

      this.$router.push("/labl/labOrders");
    },
    printResult() {
      this.$store.dispatch("resultPrint", {
        id: this.labResult.id,
      });
    },
    accept() {
      this.$store.dispatch("labResultAccept", {
        id: this.labResult.id,
      });

      this.$bvModal.show(
        "modal-sendlab-" + this.labOrder.id + "-" + this.labResult.id
      );
    },
    generateResultRows() {
      if (this.$route.params.resultId) return;
      this.form.result_data.results = [];
      this.$forceUpdate();

      for (const sample of this.labOrder?.content?.researchLines) {
        let methods = this.researchLinesRaw.filter((m) =>
          sample.research.includes(m.id)
        );

        if (this.form.result_data.groupResult == "false")
          methods = methods.filter((m) =>
            this.form.result_data.selectedMethods.includes(m.id)
          );

        let double = this.labOrder?.content?.researchLinesDouble
          .map((m) => m.research)
          .flat();
        let credited = this.labOrder?.content?.researchLinesCredited
          .map((m) => m.research)
          .flat();

        for (const method of methods) {
          let s = {
            number:
              this.form.result_data.order_number.split("/")[0] +
              "-" +
              sample.index +
              "/" +
              this.form.result_data.order_number
                .split("/")
                .splice(1, 4)
                .join("/"),
            description: sample.desc,
            param: method.parameter,
            method: method.method,
            method_id: method.id,
            result: "",
            double_result: "",
            index: sample.index,
            unit: "",
            isDouble: double.includes(method.id),
            isCredited: credited.includes(method.id),
          };

          this.form.result_data.results.push(s);
        }
      }
    },
    resultAutoComplete() {},
    async fetchData() {
      this.loader = true;
      // eslint-disable-next-line no-use-before-define
      console.log(this.$route)
      this.$store.commit("purgeLabOrders");
      this.$store.commit("purgeLabResult");

      console.log(this.labResult)

      await this.$store.dispatch("researchLinesList");

      if (this.$route.params.orderId)
        await this.$store.dispatch("getLabOrder", {
          id: this.$route.params.orderId,
        });

      if (this.$route.params.resultId)
        await this.$store.dispatch("labResultGet", {
          labResultId: this.$route.params.resultId,
        });

      console.log(this.labResult, this.$route.params.resultId)

      this.form.lab_order_id = this.$route.params.orderId;

      this.loader = false;
    },
  },
  mounted() {
    this.fetchData();
  },
  beforeUnmount() {
    this.$store.commit("purgeLabOrders");
    this.$store.commit("purgeLabResult");
  },
  watch: {
    labResult: {
      deep: true,
      handler() {
        if (null === this.labResult) 
        {
          this.result_id = 0;
          this.form.lab_order_id =this.$route.params.orderId;
          this.form.title = "";
          this.form.start_date = "";
          this.form.end_date = "";
          this.form.date_created = "";
          this.form.norm = "";
          this.form.type = "";

          this.form.result_data = {
          samplesSelected: [],
          order_number: "",
          payer: "",
          sample_type: "",
          sample_state: "",
          sampling_date: "",
          sampling_person: "",
          sample_admitted: "",
          results: [],
          groupResult: "false",
          groupResultMethod: "",
          doubleResult: "false",
          group_result: "",
          vet: {
            biogram_method: "",
            biogram_1: "",
            biogram_2: "",
            anatom: [
              {
                desc: "szt. zapalenie woreczka żółtkowego",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. skaza moczanowa nerkowa",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. przekrwienie płuc",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. zażółcenie wątroby",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. wzdęcie jelit",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. zapalny pępek",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. przekrwienie wątroby",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. przekrwienie nerek",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. włóknikowe zapalenie otrzewnej",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. włóknikowe zapalenie osierdzia",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. włóknikowe zapalenie torebki wątrobowej",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. włóknikowe zapalenie otrzewnej, osierdzia i torebki wątrobowej",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nieżyt jelit cienkich",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nieżyt jelit cienkich z obecnością ciągliwego śluzu",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obecność czopów grzybiczych w tkance płucnej",
                amount: "",
                selected: false,
              },
              {
                desc: "Inne",
                amount: "",
                selected: false,
                other: true,
              },
            ],
            parazyt: "",
            bacteria: [
              {
                desc: "szt. obfity wzrost E.coli",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost E.coli",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost E.coli",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. pojedyncze kolonie E.coli",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Staphylococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Staphylococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Staphylococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. pojedyncze kolonie Staphylococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Streptococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Streptococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Streptococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. pojedyncze kolonie Streptococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Enterococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Enterococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Enterococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. pojedyncze kolonie Enterococcus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. wzrost Proteus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. wzrost Pseudomonas aeruginosa",
                amount: "",
                selected: false,
              },
              {
                desc: "Inne",
                amount: "",
                selected: false,
                other: true,
              },
              {
                desc: "Wynik badania na obecność pałeczek z rodzaju Salmonella",
                value: false,
                salmonella: true,
                selected: false,
              },
              {
                desc: "Brak wzrostu",
                value: false,
                growth: true,
              },
            ],
            miko: [
              {
                desc: "szt. obfity wzrost Aspergillus fumigatus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Aspergillus fumigatus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Aspergillus fumigatus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Aspergillus niger",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Aspergillus niger",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Aspergillus niger",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. obfity wzrost Aspergillus flavus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. średni wzrost Aspergillus flavus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Aspergillus flavus",
                amount: "",
                selected: false,
              },
              {
                desc: "szt. nikły wzrost Aspergillus spp.",
                amount: "",
                selected: false,
              },
              {
                desc: "Inne",
                amount: "",
                selected: false,
                other: true,
              },
              {
                desc: "Brak wzrostu",
                value: false,
                growth: true,
              },
            ],
            biogram: [
              {
                desc: "AMOKSIKLAV",
                one: "",
                two: "",
              },
              {
                desc: "AMOKSYCYLINA",
                one: "",
                two: "",
              },
              {
                desc: "BIOMOX",
                one: "",
                two: "",
              },
              {
                desc: "HYDRODOXX",
                one: "",
                two: "",
              },
              {
                desc: "DOXY",
                one: "",
                two: "",
              },
              {
                desc: "SPECTRON",
                one: "",
                two: "",
              },
              {
                desc: "ENROFLOKSACYNA",
                one: "",
                two: "",
              },
              {
                desc: "FLUMEQUINA",
                one: "",
                two: "",
              },
              {
                desc: "BIOFLUMEQ",
                one: "",
                two: "",
              },
              {
                desc: "TRIMSULFASOL",
                one: "",
                two: "",
              },
              {
                desc: "METHOXASOL",
                one: "",
                two: "",
              },
              {
                desc: "FLORFENICOL",
                one: "",
                two: "",
              },
              {
                desc: "FLORON",
                one: "",
                two: "",
              },
              {
                desc: "LINCO-SPECTIN",
                one: "",
                two: "",
              },
              {
                desc: "NEOSOL",
                one: "",
                two: "",
              },
              {
                desc: "NEOMICIN",
                one: "",
                two: "",
              },
              {
                desc: "LINCOMYCIN",
                one: "",
                two: "",
              },
              {
                desc: "COLISTYNA",
                one: "",
                two: "",
              },
              {
                desc: "THIAMPHENICOL",
                one: "",
                two: "",
              },
              {
                desc: "TYLAN",
                one: "",
                two: "",
              },
              {
                desc: "OXYTERACYKLINA",
                one: "",
                two: "",
              },
              {
                desc: "MARBOCYL",
                one: "",
                two: "",
              },
              {
                desc: "BACOLAM",
                one: "",
                two: "",
              },
              {
                desc: "TIRSAN",
                one: "",
                two: "",
              },
              {
                desc: "DIFLOXY",
                one: "",
                two: "",
              },
              {
                desc: "STREPTOMYCYNA",
                one: "",
                two: "",
              },
              {
                desc: "GENTAMYCYNA",
                one: "",
                two: "",
              },
            ],
          },
          selectedMethods: [],
        };
          this.form.accepted = false;
          return
        };

        console.log(this.labResult)

        if (this.labResult.id) this.result_id = this.labResult.id;

        this.form.lab_order_id =
          this.$route.params.orderId || this.labResult.lab_order.id;
        this.form.title = this.labResult.title;
        this.form.start_date = this.labResult.start_date.date;
        this.form.end_date = this.labResult.end_date.date;
        this.form.date_created = this.labResult.date_created.date;
        this.form.norm = this.labResult.norm;
        this.form.type = this.labResult.type;
        this.form.result_data = {
          ...this.labResult.result_data,
          results: this.labResult.result_data.results.map((el) => {
            if (!el.unit) el["unit"] = "";

            return el;
          }),
        };
        this.form.accepted = this.labResult.accepted;

        if (this.labResult.accepted == 0) this.form.accepted = false;

        this.$forceUpdate();
      },
      immediate: true,
    },
    labOrder: {
      handler() {
        if (this.labResult !== null) return;

        this.form.result_data.order_number = this.labOrder?.number || "-";
        this.form.result_data.payer = this.labOrder?.client?.name || "-";
        this.form.result_data.sample_type =
          this.labOrder?.content?.questionnaire?.type || "-";
        this.form.result_data.sample_state =
          this.labOrder?.matrix == "urzedowe"
            ? this.labOrder?.content?.overview?.official?.usageGrade?.grade ==
              "true"
              ? "Bez zastrzeżeń"
              : this.labOrder?.content?.overview?.official?.usageGrade?.comment
            : this.labOrder?.content?.overview?.generic?.usageGrade?.grade ==
              "true"
            ? "Bez zastrzeżeń"
            : this.labOrder?.content?.overview?.generic?.usageGrade?.comment;
        this.form.result_data.sampling_date =
          this.labOrder?.content?.questionnaire?.samplingDate || "-";
        this.form.result_data.sampling_person =
          this.labOrder?.content?.questionnaire?.sampleCollector || "-";
        this.form.result_data.sample_admitted =
          this.labOrder?.content?.overview?.generic?.dateAdmitted || "-";

        if (this.labOrder?.content?.researchLinesDouble?.length > 0)
          this.form.result_data.doubleResult = "true";

        if (this.labOrder?.content?.researchLines) {
          this.generateResultRows();
        }

        this.$forceUpdate();
      },
      immediate: true,
    },
    "form.result_data.doubleResult"() {
      this.generateResultRows();
    },
    "form.result_data.groupResultMethod"() {
      if (
        !this.form.result_data.selectedMethods.includes(
          this.form.result_data.groupResultMethod
        )
      )
        this.form.result_data.selectedMethods.push(
          this.form.result_data.groupResultMethod
        );
      else
        this.form.result_data.selectedMethods =
          this.form.result_data.selectedMethods.filter(
            (el) => el != this.form.result_data.groupResultMethod
          );

      this.$forceUpdate();
    },
    "form.result_data.groupResult"() {
      this.generateResultRows();
    },
    "form.result_data.selectedMethods"() {
      this.generateResultRows();
    },
  },
};
</script>

<style>
table td,
table th {
  padding: 5px 15px;
}
</style>
